import React,{ Component } from 'react';
import { Menu, Select, Checkbox, Segment } from 'semantic-ui-react';

class App extends Component {

  constructor() {
    super();
    this.state = {
        rayon: 30,
        size: [0,0],
        points: [],
        arretes: [],
        chemin: "",
        deltaX: 0,
        deltaY: 0,
        pmouse: null,
        type: 1,
        options: [
          { key: 1, text: 'Cubique simple', value: 1 },
          { key: 2, text: 'Cubique centré', value: 2 },
          { key: 3, text: 'Cubique face centré', value: 3 },
          { key: 4, text: 'Chlorure de sodium', value: 4 }
        ],
        reduit: true,
        arrete: true,
        vue: 1,
        options2: [
          { key: 1, text: 'Vue 3d', value: 1 },
          { key: 2, text: 'Vue en coupe diagonale', value: 2 },
          { key: 3, text: 'Vue du dessus', value: 3 }
        ],
        disabled: false,
        color : { color1 : "#5050d6", color2 : "#0000AA", color3 : "#000066", color4 : "blue"},
        choixColor : 1,
        options3: [
          { key: 1, text: 'Couleur bleu', value: 1 },
          { key: 2, text: 'Couleur rouge', value: 2 },
          { key: 3, text: 'Couleur vert', value: 3 }
        ],
    };

    this.rotationZ = this.rotationZ.bind(this);
    this.rotationY = this.rotationY.bind(this);
    this.rotationX = this.rotationX.bind(this);
    this.afficheArrete = this.afficheArrete.bind(this);
    this.changeOption = this.changeOption.bind(this);
    this.changeCheck = this.changeCheck.bind(this);
    this.changeArrete = this.changeArrete.bind(this);
    this.changeOption2 = this.changeOption2.bind(this);
    this.changeOption3 = this.changeOption3.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  async startDrag(event) {

    if(this.state.vue === 1){

    event.preventDefault();
    let point = this.svg.createSVGPoint();
    if (event.touches) { event = event.touches[0]; }
    point.x = event.clientX;
    point.y = event.clientY;
    //point = point.matrixTransform(this.svg.getScreenCTM().inverse());

    this.setState({
      pmouse: point
    })
    
    const mousemove = async (event) => {

      event.preventDefault();
      let cursor = this.svg.createSVGPoint();
      if (event.touches) { event = event.touches[0]; }
      cursor.x = event.clientX;
      cursor.y = event.clientY;
      //cursor = cursor.matrixTransform(this.svg.getScreenCTM().inverse());

      let deltaX = 0;
      let deltaY = 0;

      deltaX = (cursor.x-this.state.pmouse.x)*0.4;

      console.log(cursor.x);
      console.log(this.state.pmouse.x);
      console.log(deltaX);

      deltaY = (cursor.y-this.state.pmouse.y)*0.4;
      console.log(deltaY);


      this.rotationY(deltaX);
      this.rotationX(deltaY);

      this.afficheArrete();

      this.setState({
          pmouse: cursor
      })

        
    };
    
    const mouseup = async (event) => {

      document.removeEventListener("mousemove", mousemove);
      document.removeEventListener("mouseup", mouseup);
      document.removeEventListener("touchmove", mousemove);
      document.removeEventListener("touchend", mouseup);
    };
    
    document.addEventListener("touchmove", mousemove);      
    document.addEventListener("touchend", mouseup);
    document.addEventListener('touchleave', mouseup);
    document.addEventListener('touchcancel', mouseup);

    document.addEventListener("mousemove", mousemove);      
    document.addEventListener("mouseup", mouseup);

    }

  }

  async componentDidMount(){

      let lespoints = [[-100,-100,-100,0],[-100,-100,100,1],[-100,100,-100,2],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7]];

      let lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];

      await this.setState({
        points : lespoints,
        arretes : lesarretes,
        size : [window.innerWidth, window.innerHeight],
      });

      window.addEventListener('resize', this.updateSize);

      this.rotationZ(50);
      this.rotationY(50);
      this.rotationX(50);
      this.afficheArrete();

  }

  updateSize(){
    this.setState({
      size : [window.innerWidth, window.innerHeight],
    });
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.updateSize);
  }

  async rotationZ(teta){
    var sinTheta = Math.sin(teta*Math.PI/180);
    var cosTheta = Math.cos(teta*Math.PI/180);

    let lespoints = this.state.points;

    for (var n = 0; n < lespoints.length; n++) {
        var point = lespoints[n];
        var x = point[0];
        var y = point[1];
        lespoints[n][0] = (x * cosTheta - y * sinTheta);
        lespoints[n][1] = (y * cosTheta + x * sinTheta);
    }

    await this.setState({
        points: lespoints.sort((a,b) => a[2] - b[2]),
    });

  }

  async rotationY(teta){
    var sinTheta = Math.sin(teta*Math.PI/180);
    var cosTheta = Math.cos(teta*Math.PI/180);

    let lespoints = this.state.points;

    for (var n = 0; n < lespoints.length; n++) {
        var point = lespoints[n];
        var x = point[0];
        var z = point[2];
        lespoints[n][0] = (x * cosTheta + z * sinTheta);
        lespoints[n][2] = (z * cosTheta - x * sinTheta);
    }

    await this.setState({
        points: lespoints.sort((a,b) => a[2] - b[2]),
    });

  }

  async rotationX(teta){
    var sinTheta = Math.sin(teta*Math.PI/180);
    var cosTheta = Math.cos(teta*Math.PI/180);

    let lespoints = this.state.points;

    for (var n = 0; n < lespoints.length; n++) {
        var point = lespoints[n];
        var y = point[1];
        var z = point[2];
        lespoints[n][1] = (y * cosTheta - z * sinTheta);
        lespoints[n][2] = (z * cosTheta + y * sinTheta);
    }

    await this.setState({
        points: lespoints.sort((a,b) => a[2] - b[2]),
    });

  }

  afficheArrete(){

    let lechemin = "";
    let lespoints = this.state.points;

    for(var i=0;i<this.state.arretes.length;i++){

      const po = i;

      let Idepart = -1;
      let Ifin = -1;

      for(var j =0; j< lespoints.length; j++){
          if(lespoints[j][3] === this.state.arretes[po][0]){
            Idepart = j;
          }
      }

      for(var k =0; k< lespoints.length; k++){
          if(lespoints[k][3] === this.state.arretes[po][1]){
            Ifin = k;
          }
      }

      lechemin += " M " + lespoints[Idepart][0] + " " + lespoints[Idepart][1] +  " L " + lespoints[Ifin][0] + " " + lespoints[Ifin][1];

    }

    this.setState({
        chemin: lechemin
    });

  }

  async changeOption(e, {value}){
    this.setState({
      type: value,
      vue : 1
    });

    let lespoints = [];
    let lesarretes = [];

    if(value === 2){
        lespoints = [[-100,-100,-100,0],[-100,-100,100,1],[-100,100,-100,2],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7],[0,0,0,8]];
        lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];     
    }else if(value === 1){
        lespoints = [[-100,-100,-100,0],[-100,-100,100,1],[-100,100,-100,2],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7]];
        lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];
    }else if(value === 3){
        lespoints = [[-100,-100,-100,0],[-100,-100,100,1],[-100,100,-100,2],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7],[0,0,100,8],[0,0,-100,9],[0,100,0,10],[100,0,0,11],[0,-100,0,12],[-100,0,0,13]];
        lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];     
    }else if(value === 4){
        lespoints = [[-100,-100,-100,0],[-100,-100,100,1],[-100,100,-100,2],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7],[0,0,100,8],[0,0,-100,9],[0,100,0,10],[100,0,0,11],[0,-100,0,12],[-100,0,0,13],[100,100,0,14],[-100,-100,0,15],[-100,100,0,16],[100,-100,0,17],[0,-100,-100,18],[0,-100,100,19],[0,100,-100,20],[0,100,100,21],[100,0,100,22],[-100,0,-100,23],[-100,0,100,24],[100,0,-100,25],[0,0,0,26]];
        lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];    
    }

    await this.setState({
        points : lespoints,
        arretes : lesarretes,
        disabled: false,
        reduit : true,
        arrete: true,
        rayon: 30
    });

    this.rotationZ(50);
    this.rotationY(50);
    this.rotationX(50);
    this.afficheArrete();

  }

  changeCheck(e,{checked}){
      this.setState({
        reduit : checked
      });

      if(checked === false){
        if(this.state.type === 1){
            this.setState({
                rayon: 100
            });
        }else if(this.state.type === 2){
            this.setState({
              rayon: (200*Math.sqrt(3)/4)
            });
        }else{
            this.setState({
              rayon: (200*Math.sqrt(2)/4)
            });
        }
      }else{
          this.setState({
            rayon: 30
        })
      }
  }

  changeArrete(e, {checked}){
    this.setState({
      arrete : checked
    });
  }

  async changeOption2(e, {value}){
    this.setState({
      vue: value
    });

    let lespoints = [];
    let lesarretes = [];

    if(value === 3 && this.state.type === 1){
        lespoints = [[-100,-100,-100,0],[-100,-100,100,1],[-100,100,-100,2],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7]];
        lesarretes  = [[3, 2],[2, 0],[4, 5],[5, 7],[0, 4],[1, 5],[2, 6],[3, 7]];

        await this.setState({
            points : lespoints,
            arretes : lesarretes,
            arrete: false,
            disabled: true,
            reduit : false
        });

        this.rotationZ(0);
        this.rotationY(0);
        this.rotationX(0);
        this.afficheArrete();
    }else if(value === 2 && this.state.type === 1){

      lespoints = [[-100,-100,100,1],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7]];
      //lesarretes  = [[3, 2],[2, 0],[4, 5],[5, 7],[0, 4],[1, 5],[2, 6],[3, 7]];

      await this.setState({
          points : lespoints,
          arretes : lesarretes,
          arrete: false,
          disabled: true,
          reduit : false,        
      });

      this.rotationZ(0);
      this.rotationY(135);
      this.rotationX(0);
      this.afficheArrete();

    }else if(value === 1 && this.state.type === 1){


      lespoints = [[-100,-100,-100,0],[-100,-100,100,1],[-100,100,-100,2],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7]];
      lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];

      await this.setState({
          points : lespoints,
          arretes : lesarretes,
          disabled : false,
          reduit : true,
          arrete : true,
      });

      this.rotationZ(50);
      this.rotationY(50);
      this.rotationX(50);
      this.afficheArrete();

    }else if(value === 1 && this.state.type === 2){
        lespoints = [[-100,-100,-100,0],[-100,-100,100,1],[-100,100,-100,2],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7],[0,0,0,8]];
        lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];
        
        await this.setState({
          points : lespoints,
          arretes : lesarretes,
          disabled : false,
          reduit : true,
          arrete : true,
      });

      this.rotationZ(50);
      this.rotationY(50);
      this.rotationX(50);
      this.afficheArrete();

    }else if(value === 3 && this.state.type === 2){

      lespoints = [[-100,-100,-100,0],[-100,-100,100,1],[-100,100,-100,2],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7],[0,0,0,8]];
      lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];
        
      await this.setState({
          points : lespoints,
          arretes : lesarretes,
          arrete: false,
          disabled: true,
          reduit : false
      });

      this.rotationZ(0);
      this.rotationY(0);
      this.rotationX(0);
      this.afficheArrete();

    }else if(value === 2 && this.state.type === 2){

        lespoints = [[-100,-100,100,1],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7],[0,0,0,8]];
        //lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];

        await this.setState({
            points : lespoints,
            arretes : lesarretes,
            arrete: false,
            disabled: true,
            reduit : false,        
        });

        this.rotationZ(0);
        this.rotationY(135);
        this.rotationX(0);
        this.afficheArrete();

    }else if(value === 1 && this.state.type === 3){

      lespoints = [[-100,-100,-100,0],[-100,-100,100,1],[-100,100,-100,2],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7],[0,0,100,8],[0,0,-100,9],[0,100,0,10],[100,0,0,11],[0,-100,0,12],[-100,0,0,13]];
      lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];

      await this.setState({
          points : lespoints,
          arretes : lesarretes,
          disabled : false,
          reduit : true,
          arrete : true,
      });

      this.rotationZ(50);
      this.rotationY(50);
      this.rotationX(50);
      this.afficheArrete();

    }else if(value === 3 && this.state.type === 3){

      lespoints = [[-100,-100,-100,0],[-100,-100,100,1],[-100,100,-100,2],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7],[0,0,100,8],[0,0,-100,9],[0,100,0,10],[100,0,0,11],[0,-100,0,12],[-100,0,0,13]];
      lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];

      await this.setState({
          points : lespoints,
          arretes : lesarretes,
          arrete: false,
          disabled: true,
          reduit : false
      });

      this.rotationZ(0);
      this.rotationY(0);
      this.rotationX(0);
      this.afficheArrete();

    }else if(value === 2 && this.state.type === 3){

      lespoints = [[-100,-100,-100,0],[-100,-100,100,1],[-100,100,-100,2],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7],[0,0,100,8],[0,100,0,10],[100,0,0,11],[0,-100,0,12]];
      //lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];

      await this.setState({
          points : lespoints,
          arretes : lesarretes,
          arrete: false,
          disabled: true,
          reduit : false,        
      });

      this.rotationZ(0);
      this.rotationY(135);
      this.rotationX(0);
      this.afficheArrete();

    }else if(value === 1 && this.state.type === 4){

      lespoints = [[-100,-100,-100,0],[-100,-100,100,1],[-100,100,-100,2],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7],[0,0,100,8],[0,0,-100,9],[0,100,0,10],[100,0,0,11],[0,-100,0,12],[-100,0,0,13],[100,100,0,14],[-100,-100,0,15],[-100,100,0,16],[100,-100,0,17],[0,-100,-100,18],[0,-100,100,19],[0,100,-100,20],[0,100,100,21],[100,0,100,22],[-100,0,-100,23],[-100,0,100,24],[100,0,-100,25],[0,0,0,26]];
      lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];

      await this.setState({
        points : lespoints,
        arretes : lesarretes,
        disabled : false,
        reduit : true,
        arrete : true,
      });

      this.rotationZ(50);
      this.rotationY(50);
      this.rotationX(50);
      this.afficheArrete();

    }else if(value === 3 && this.state.type === 4){

      lespoints = [[-100,-100,-100,0],[-100,-100,100,1],[-100,100,-100,2],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7],[0,0,100,8],[0,0,-100,9],[0,100,0,10],[100,0,0,11],[0,-100,0,12],[-100,0,0,13],[100,100,0,14],[-100,-100,0,15],[-100,100,0,16],[100,-100,0,17],[0,-100,-100,18],[0,-100,100,19],[0,100,-100,20],[0,100,100,21],[100,0,100,22],[-100,0,-100,23],[-100,0,100,24],[100,0,-100,25]];
      lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];

      await this.setState({
          points : lespoints,
          arretes : lesarretes,
          arrete: false,
          disabled: true,
          reduit : false
      });

      this.rotationZ(0);
      this.rotationY(0);
      this.rotationX(0);
      this.afficheArrete();

    }else if(value === 2 && this.state.type === 4){

      lespoints = [[-100,-100,100,1],[-100,100,100,3],[100,-100,-100,4],[100,-100,100,5],[100,100,-100,6],[100,100,100,7],[0,0,100,8],[0,100,0,10],[100,0,0,11],[0,-100,0,12],[100,100,0,14],[100,-100,0,17],[0,-100,100,19],[0,100,100,21],[100,0,100,22],[-100,0,100,24],[100,0,-100,25],[0,0,0,26]];
      //lesarretes  = [[0, 1],[1, 3],[3, 2],[2, 0],[4, 5],[5, 7],[7, 6],[6, 4],[0, 4],[1, 5],[2, 6],[3, 7]];

      await this.setState({
          points : lespoints,
          arretes : lesarretes,
          arrete: false,
          disabled: true,
          reduit : false,        
      });

      this.rotationZ(0);
      this.rotationY(135);
      this.rotationX(0);
      this.afficheArrete();

    }

    if(this.state.reduit === false){
      if(this.state.type === 1){
          this.setState({
              rayon: 100
          });
      }else if(this.state.type === 2){
          this.setState({
            rayon: (200*Math.sqrt(3)/4)
          });
      }else if(this.state.type === 3){
          this.setState({
            rayon: (200*Math.sqrt(2)/4)
          });
      }else{
        this.setState({
            rayon: (200*Math.sqrt(2)/4)
        });
      }
    }else{
        this.setState({
          rayon: 30
      })
    }
  }

  changeOption3(e, {value}){

    if(value === 1){
      this.setState({
          color : { color1 : "#5050d6", color2 : "#0000AA", color3 : "#000066", color4 : "blue"},
          choixColor : 1
      })
    }else if(value === 2){
      this.setState({
        color : { color1 : "#db5050", color2 : "#AA0000", color3 : "#8b0000", color4 : "red"},
        choixColor : 2
      })
    }else{
      this.setState({
        color : { color1 : "#e5f2e5", color2 : "#66b266", color3 : "#008000", color4 : "green"},
        choixColor : 3
      })
    }

  }

  render(){
    return (
      <div className="svg-container">
        <svg id="container" viewBox={`${-window.innerWidth/2} ${-window.innerHeight/2} ${window.innerWidth} ${window.innerHeight}`} height="100%" width="100%" preserveAspectRatio="xMinYMin meet" className="svg-content" ref={(svg) => this.svg = svg} onMouseDown={(e) => this.startDrag(e)} onTouchStart={(e) => this.startDrag(e)}>
          
          <defs>

            <radialGradient id="blueCircle"
              gradientUnits="objectBoundingBox" fx="30%" fy="30%">

              <stop offset="0%" stopColor={this.state.color.color1} />
              <stop offset="40%" stopColor={this.state.color.color2} />
              <stop offset="100%" stopColor={this.state.color.color3} />
            </radialGradient>

            <radialGradient id="GreenCircle"
              gradientUnits="objectBoundingBox" fx="30%" fy="30%">

              <stop offset="0%" stopColor="#e5f2e5" />
              <stop offset="40%" stopColor="#66b266" />
              <stop offset="100%" stopColor="#008000" />
            </radialGradient>

            <radialGradient id="YellowCircle"
              gradientUnits="objectBoundingBox" fx="30%" fy="30%">

              <stop offset="0%" stopColor="#ffffcc" />
              <stop offset="40%" stopColor="#ffff66" />
              <stop offset="100%" stopColor="#ffff00" />
            </radialGradient>

          </defs>

          {
            this.state.points && (
              this.state.points.map((element,i) => {
                if(this.state.type === 4){

                  if(this.state.vue !== 2){
                      if(element[3] >= 14){
                        return (
                          <circle key={i} cx={element[0]} cy={element[1]} r={this.state.rayon*(Math.sqrt(2)-1)} fill="url(#YellowCircle)"/>
                        )
                      }else{
                        return (
                          <circle key={i} cx={element[0]} cy={element[1]} r={this.state.rayon} fill="url(#GreenCircle)"/>
                        )
                      }
                  }else{
                    if(element[1] !== 0){
                      if(element[3] >= 14){
                          return (
                            <circle key={i} cx={element[0]} cy={element[1]} r={this.state.rayon*(Math.sqrt(2)-1)} fill="#ffff00"/>
                          )
                        }else{
                          return (
                            <circle key={i} cx={element[0]} cy={element[1]} r={this.state.rayon} fill="#008000"/>
                          )
                        }
                    }else{
                        if(element[3] >= 14){
                          return (
                            <circle key={i} cx={element[0]} cy={element[1]} r={this.state.rayon*(Math.sqrt(2)-1)} fill="#ffff00"/>
                          )
                        }else{
                          return (
                            <circle key={i} cx={element[0]} cy={element[1]} r={this.state.rayon} fill="url(#GreenCircle)"/>
                          )
                        }
                    }
                  }

                }else{
                  if(this.state.vue !== 2){
                    return (
                      <circle key={i} cx={element[0]} cy={element[1]} r={this.state.rayon} fill="url(#blueCircle)"/>
                    )
                  }else{

                    if(this.state.type !== 3){

                        if(!(element[0] <= 0 || element[2] > 0)){
                          return (
                            <circle key={i} cx={element[0]} cy={element[1]} r={this.state.rayon} fill="url(#blueCircle)"/>
                          )
                        }else{
                          return (
                            <circle key={i} cx={element[0]} cy={element[1]} r={this.state.rayon} fill={this.state.color.color3}/>
                          )
                        }

                    }else{
                      
                      if(!(element[1] !== 0)){
                          return (
                            <circle key={i} cx={element[0]} cy={element[1]} r={this.state.rayon} fill="url(#blueCircle)"/>
                          )
                      }else{
                          return (
                            <circle key={i} cx={element[0]} cy={element[1]} r={this.state.rayon} fill={this.state.color.color3}/>
                          )
                      }

                    }

                  }
                }
              })
            )
          }

          {
            (this.state.chemin && this.state.arrete === true) && (
              this.state.type === 4 ? 
                  <path d={this.state.chemin} stroke="green" strokeWidth="2" strokeDasharray="4" fill="none"/>
              :
                  <path d={this.state.chemin} stroke={this.state.color.color4} strokeWidth="2" strokeDasharray="4" fill="none"/>
            )
          }
         
        </svg>
        <Menu inverted compact style={{position: "absolute", top: "10px", right: "10px"}}>
            <Segment compact style={{padding : "8px"}}>
              <Checkbox toggle checked={this.state.reduit} onChange={this.changeCheck} label='Taille réduit des atomes' style={{marginRight: "20px"}} disabled={this.state.disabled}/>
              <Select value={this.state.type} style={{width: "13em"}}  compact selection options={this.state.options} onChange={this.changeOption}/>
            </Segment>       
        </Menu>

        <Menu inverted compact style={{position: "absolute", bottom: "10px", right: "10px"}}>
            <Segment compact>
              <Checkbox toggle checked={this.state.arrete} onChange={this.changeArrete} label='Afficher les arêtes en pointillé' disabled={this.state.disabled}/>
            </Segment>
        </Menu>

        <Menu inverted compact style={{position: "absolute", top: "10px", left: "10px"}}>
            <Segment compact style={{padding : "8px"}}>
              <Select value={this.state.vue} style={{width: "16em"}}  compact selection options={this.state.options2} onChange={this.changeOption2}/>
            </Segment>
        </Menu>

        {
          this.state.type !== 4 && (
            <Menu inverted compact style={{position: "absolute", bottom: "10px", left: "10px"}}>
              <Segment compact style={{padding : "8px"}}>
                  <Select value={this.state.choixColor} style={{width: "10em"}}  compact selection options={this.state.options3} onChange={this.changeOption3}/>
              </Segment>
            </Menu>
          )
        }
        
    </div>
    );
  }
}

export default App;

